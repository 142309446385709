/* ===============================
          TailWind
     =============================== */
     @import "tailwindcss/base";
     @import "tailwindcss/components";
     @import "tailwindcss/utilities";

/* ===============================
            Variables
   =============================== */
:root {
  --main-color: #1b3427;
  --secondary-color: #265073;
  --accent-color: #EEF5FF;
  --text-color: #f8f0e5;
  --highlight-color: #eadbc8;
  --darker-main-color: #02161f;
}

/* ===============================
                Global
       =============================== */
* {
  font-family: 'Ubuntu', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: linear-gradient(to bottom, #0e0f0f, #acd6e0); /* Example gradient */
  color: var(--text-color);
  min-height: 100vh; /* Asegura que el fondo cubra toda la altura del viewport */
  margin: 0; /* Elimina cualquier margen por defecto */
}


/* ===============================
                Flex Container
       =============================== */
/* Flex Container */
.flex-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

/* Logo Container */
.logo-container {
  flex: 0 0 20%;
  position: fixed;
  z-index: 150;
  top: 10px;
}

/* Header Container */
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  background-color: #1b3427;
  transition: top 0.3s;
}

/* Smooth transitions for appearing and disappearing */
.header-container.visible {
  top: 0;
}

.header-container.hidden {
  top: -100%; /* Adjust based on header height */
}

.header-nav {
  display: flex;
  gap: 20px; /* Adjust the space between navigation links */
}

.header-nav a {
  color: var(--text-color);
  text-decoration: none;
  transition: color 0.3s;
}

.header-nav a:hover {
  color: var(--accent-color);
}

.burger-container {
  flex: 0 0 20%;
}

/* Logo */
.logo-container img {
  border-radius: 50%;
  width: 50px; /* Se ajustará al 10% del contenedor padre */
  height: 50px;
  cursor: pointer;
  margin-left: 10px;
}

/* ===============================
           Nueva sección para el contenedor padre del BurgerMenu
       =============================== */
.menu-container {
  position: relative; /* Establece un nuevo contexto de apilamiento */
  display: flex;
  justify-content: space-between;
  align-items: center; /* Alinea verticalmente los elementos hijos */
  width: 100%;
}

.menu-container > .burger-container {
  width: 10%;
}

.menu-container > .header-container {
  width: 90%;
}

/* ===============================
                Header
       =============================== */
.header-container {
  position: fixed;
  top: 0;
  width: 100%;
  height: 70px;
  background-color: var(--darker-main-color);
  color: var(--text-color);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 101;
}

.header-container button {
  margin: 10px;
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  color: var(--text-color);
  text-decoration: none;
  transition: all 0.3s ease;
}

.header-container button:hover {
  text-decoration: underline;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* ===============================
             Burger Menu
       =============================== */
.burger-container {
  position: fixed;
  top: 0;
  right: 0;
  height: 70px;
  display: flex;
  align-items: center;
  z-index: 200;
}

.burger-button {
  position: relative;
  cursor: pointer;
  width: 30px;
  height: 24px;
  transition: all 0.3s ease;

}

.burger-line {
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: var(--text-color);
  transition: all 0.3s ease;
  background-color: var(--text-color);

}

.burger-line:nth-child(1) {
  top: 0;
}
.burger-line:nth-child(2) {
  top: 50%;
  transform: translateY(-50%);
}
.burger-line:nth-child(3) {
  bottom: 0;
}

/* Animation for burger button when open */
.burger-button.open .burger-line:nth-child(1) {
  transform: translateY(12px) rotate(45deg);
}
.burger-button.open .burger-line:nth-child(2) {
  opacity: 0;
}
.burger-button.open .burger-line:nth-child(3) {
  transform: translateY(-12px) rotate(-45deg);
}

.burger-menu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
  background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent background */
  color: var(--text-color);
  width: 200px;
  height: 100vh;
  display: none; /* Initially hidden */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease, display 0.3s ease;
  transform: translateX(100%);
}

.burger-menu.show {
  display: flex; /* Show the menu */
  transform: translateX(0);
}


.burger-menu a {
  padding: 10px 0;
  text-decoration: none;
  color: var(--text-color);
}

.menu-link {
  padding: 15px 20px;
  text-decoration: none;
  color: var(--text-color);
  transition: background-color 0.3s ease;
}

.menu-link:hover {
  background-color: var(--main-color); /* Change color on hover */
}

/* ===============================
             Layout
     =============================== */

     .main-content {
      position: relative;
      width: 100%;
      padding-top: 120px;
  }

  .logo-fixed {
    position: fixed; /* Mantiene el logo fijo en su lugar */
    top: 0; /* Ajusta según la posición deseada */
    left: 0; /* Ajusta según la posición deseada */
    z-index: 100; /* Asegura que el logo se mantenga por encima de otros elementos */
  }
  
  .banner-container {
      background-size: cover; /* O 'contain', según lo que prefieras */
      position: relative;
      width: 100%;
      height: 300px; /* O la altura que prefieras */
  }
  
  .intro-dona-container {
      display: flex;
      width: 100%;
      height: 100%;
  }
  
  .intro-section {
      position: relative;
      width: 50%;
      padding: 20px;
      box-sizing: border-box;
      background-color: transparent;
      color: white;
  }
  
  #bg { /* Asumiendo que este es el ID del canvas de Dona */
      width: 50%;
      height: 100%;
  }
  

.component-left,
.component-right {
  position: relative;
  width: 100%;
  padding: 10px 0;
}

.component-left {
  text-align: left;
}

.component-right {
  text-align: right;
}



/* ===============================
             Spacing
     =============================== */
#aboutMe,
#softSkills,
#hardSkills,
#funFacts {
  padding: 20px;
}

/* ===============================
          IntroSection
     =============================== */

     .intro-section {
      text-align: center;
      background-color: rgba(0, 0, 0, 0.1);/* Your gradient colors */
      color: var(--text-color);
      border-radius: 15px; /* Softens edges */
      padding: 30px; /* Adds internal space */
      margin: 20px 0; /* External spacing */
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4); /* Subtle shadow for depth */
    }
    
    .main-text {
      font-size: 2em;
      color: var(--accent-color);
      text-shadow: 1px 1px 2px black; /* Improves readability on gradient */
      animation: fadeIn 2s ease;
    }

.name-text {
  font-size: 1.5em;
  color: rgb(234, 225, 225); /* Tonalidad más oscura */
}

.sub-text {
  font-size: 1.2em;
}

.description-text {
  font-size: 1em;
  color: var(--highlight-color);/* Un color que resalte pero que siga la paleta */
  animation:
    typing 5s steps(40) forwards,
    blink 1s step-end infinite;
  overflow: hidden;
  white-space: nowrap;
  border-right: 2px solid var(--text-color); /* Simula el cursor */
  display: inline-block;
  margin: auto;
}

/* Animación de aparición gradual */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1; /* Changed to ensure visibility at the end */
  }
}

/* Efecto de escritura */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    /*Se calcula usando una función en IntroSection.jsx */
  }
}

.transparent {
  color: transparent !important;
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

/* ===============================
          Footer/Contact
     =============================== */
footer {
  display: flex;
  flex-direction: column;
  align-items: center; /* Esto centrará todo el contenido horizontalmente */
  padding: 30px 0; /* Esto agrega 30px de espacio arriba y abajo */
  text-align: center; /* Centrar el texto */
}

.contact-info {
  display: flex;
  justify-content: center; /* Centrar los spans entre sí */
  width: 100%; /* Ocupa todo el ancho disponible */
  max-width: 600px; /* Puedes ajustar esto según tus necesidades */
}

.contact-info span {
  margin: 0 20px; /* Agrega margen a los lados de los spans */
}

/* ===============================
          HardSkills Animations
     =============================== */


.progress-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.language {
  flex-basis: 20%;
}

.progress {
  flex-grow: 1;
  height: 20px;
  background-color: var(--highlight-color);
  margin: 0 10px;
  position: relative;
}

.progress-bar {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-color: green;
  animation: fillBar 2s forwards;
}

.percentage {
  flex-basis: 10%;
  animation: countUp 4s forwards;
}

@keyframes fillBar {
  from {
    width: 0;
  }
}

@keyframes countUp {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.tech-container {
  display: flex;
  align-items: center; /* Esto asegura que ambos hijos tengan la misma altura */
  justify-content: space-between; /* Esto coloca un espacio entre los elementos flex */
  gap: 20px; /* Ajusta este valor si necesitas más espacio entre elementos */
}

.no-bullets {
  list-style-type: none; /* Elimina los bullets */
  flex: 1; /* Permite que la lista crezca y llene el espacio asignado */
  margin: 0;
  padding: 0;
}

/* Ajusta el componente BarChart para que utilice el espacio de manera efectiva */
.BarChart {
  flex: 3; /* Aumenta el espacio que el gráfico puede crecer en comparación con la lista */
  position: relative;
  /* El margin-right puede eliminarse si estás utilizando gap en el contenedor */
}

.no-bullets li {
  /* Estilos base para la lista */
  cursor: pointer;
  transition: transform 0.3s ease;
  list-style-type: none;
}

.no-bullets li:hover {
  /* Estilos para hover */
  transform: scale(1.1);
}

.no-bullets li.active {
  /* Estilos para el elemento activo */
  color: var(
    --accent-color
  ); /* Cambia el color para resaltar el texto activo */
  font-weight: bold;
}

.BarChart .bar {
  /* Estilos base para las barras */
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
}

.BarChart .bar.active {
  /* Estilos para la barra activa */
  transform: translateZ(20px); /* Crea un efecto 3D */
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3); /* Sombreado para profundidad */
}

.skills-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}

/* Elimina los bullets de la lista */
#programmingLanguagesList ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

/* Estilos base para los elementos de la lista */
#programmingLanguagesList li {
  cursor: pointer;
  transition: transform 0.3s ease;
  padding: 5px 0; /* Ajusta el espaciado según sea necesario */
}

/* Estilos para hover */
#programmingLanguagesList li:hover {
  transform: scale(1.1);
}

/* Estilos para el elemento activo */
#programmingLanguagesList li.active {
  color: var(--accent-color); /* Cambia el color del texto a rojo */
  font-weight: bold; /* Hace el texto en negrita */
}

.chart-info {
  position: relative;
  bottom: 60px;
  transform: translate(-50%, -50%);
  color: #f8f0e5; /* El color del texto */
  font-size: 1.2em; /* El tamaño del texto */
  transform: translateY(-400%);
  transform: translatex(-100%);
}

.skills-layout {
  display: flex;
  align-items: center;
}

.skills-list {
  flex-grow: 1;
}

.skills-container {
  display: flex;
  align-items: center;
}

#programmingLanguagesList ul {
  padding-left: 0; /* Quita el padding por defecto */
  list-style-type: none; /* Quita las viñetas */
}

.skills-chart {
  flex-grow: 0;
  max-width: 150px; /* Ajusta según el tamaño deseado del gráfico */
  height: auto;
  position: relative;
  margin-left: 250px;
}

.skills-chart canvas {
  width: 100% !important;
  height: auto !important;
}

.radar-container {
  display: flex;
  text-align: center;
  flex-direction: column; /* Esto alinea los elementos verticalmente */
  align-items: center; /* Esto centra los elementos horizontalmente */
  justify-content: center; /* Esto centra los elementos verticalmente, pero solo tiene efecto si hay espacio extra en el contenedor */
  margin-top: 8%;
  margin-bottom: 5%;
}

/* ===============================
          SoftSkills Animations
     =============================== */
/* Soft Skill Sections */
.main-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between; /* Alinea los elementos a los extremos y al centro */
  align-items: center; /* Alinea los elementos verticalmente en el centro */
  margin: 0 auto;
  padding: 20px; /* Ajusta según sea necesario */
  max-width: auto; /* Ajusta según el ancho máximo deseado */
}

.softSkill-left, .softSkill-right, .softSkill-center {
  flex: 0 0 30%; /* Asegura que no crezcan o encogan más allá de 30% */
  padding: 20px;
  background-color: var(--darker-main-color);
  color: var(--text-color);
  border-radius: 25px;
  text-align: center;
  box-sizing: border-box;
}

.softSkill-center {
  flex: 0 0 70%; /* Ajusta el ancho del elemento central a 70% */
  margin: 0 20px; /* Asegura espacio entre los elementos */
}


.main-container::after {
  content: "";
  display: table;
  clear: both;
}

/* ===============================
                Hobbies
       =============================== */

       .interest-list {
        list-style-type: none; /* Quita los puntos de la lista */
        padding: 0;
        
      }
      
      .left-item, .right-item {
        display: flex;
        align-items: center;
        margin-bottom: 20px; /* Espacio entre los elementos */
        background-color: white; /* Fondo blanco para cada elemento */
        border-radius: 25px; /* Borde redondeado */
        color: #EEF5FF; /* Cambia el color del texto */
        max-width: 100%; /* Reduce el ancho máximo */
        margin-left: auto; /* Centra los elementos de la izquierda */
        margin-right: auto; /* Centra los elementos de la derecha */
      }
     
      
      .left-item .interest-gif, .right-item .interest-gif {
        width: 50px; /* Ajusta el tamaño del GIF */
        height: auto;
        margin: 0 15px; /* Espacio alrededor del GIF */
      }
      
      .left-item {
        flex-direction: row;
        padding: 10px 20px; /* Relleno interno */
      }
      
      .right-item {
        flex-direction: row-reverse;
        padding: 10px 20px; /* Relleno interno */
      }
      
      .team-carousel {
        display: flex;
        justify-content: center;
        
      }
      
      .team-item {
        transform: scale(0.8);
        transition: transform 0.5s ease, box-shadow 0.3s ease;
        opacity: 0.5;
        margin: 20px; /* Increased margin for better spacing */
        position: relative;
        cursor: pointer; /* Indicates the item is interactive */
        border-radius: 10px; /* Rounded corners */
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow */
      }
      
      .team-item.active {
        transform: scale(1);
        opacity: 1;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* More pronounced shadow for active item */
      }
      
      .team-item img {
        display: block;  /* Corrección: cambia 'circle' a 'block' */
        margin: auto;
        width: 150px;  /* Ancho fijo */
        height: 150px; /* Altura fija, igual al ancho para mantener las imágenes circulares */
        border-radius: 50%; /* Mantiene las imágenes circulares */
        object-fit: cover; /* Asegura que el contenido de la imagen se cubra adecuadamente sin distorsionar */
      }
      
      
      .team-sport, .team-name {
        position: absolute;
        width: 100%;
        text-align: center;
        color: var(--accent-color); /* Uso de un color de acento */
      }
      
      .team-sport {
        top: 0;  /* Ajuste para alinear correctamente con la parte superior del contenedor */
        font-size: 0.8em;
        transform: translateY(-100%); /* Desplaza completamente fuera de la parte superior de la imagen */
      }
      
      .team-name {
        bottom: 0;  /* Alinea con la parte inferior del contenedor */
        font-size: 0.9em;
        transform: translateY(100%); /* Desplaza completamente fuera de la parte inferior de la imagen */
      }
      
      
      .team-item:hover {
        transform: scale(0.9); /* Slight enlargement on hover */
      }
      

      .carousel-container {
        display: flex;
        overflow-x: auto;
        white-space: nowrap;
        gap: 20px;
        padding: 20px;
        scrollbar-width: none; /* Hide scrollbar for a cleaner look */
      }

.team-carousel, .movie-carousel, .book-carousel, .food-carousel {
  display: flex;
  overflow-x: auto;
  white-space: nowrap; /* Esto es importante para mantener todos los elementos en una sola línea */
  padding: auto;
}

.carousel-item {
  display: inline-block;
  margin-right: 10px;
  transition: transform 0.3s ease;
}

.carousel-item:hover {
  transform: scale(1.1); /* Slightly enlarge on hover */
}


/* Estilos específicos para el carrusel de películas y libros */
.movie-carousel .carousel-item img,
.book-carousel .carousel-item img {
  width: 150px;
  height: 250px;
  border-radius: 10px; /* Rounded corners for images */
}

.carousel-item p {
  text-align: center;
  margin-top: 5px;
}

.food-carousel {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding: 20px;
  gap: 20px; /* Space between carousel items */
  scrollbar-width: none; /* Optionally hide the scrollbar for a cleaner look */
}

.food-carousel .carousel-item {
  display: inline-block;
  text-align: center; /* Center the text below the image */
  margin-right: 10px;
}

.food-carousel .carousel-item img {
  width: 150px; /* Square size */
  height: 150px;
  border-radius: 15px; /* Rounded corners for a softer look */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effects */
}

.food-carousel .carousel-item img:hover {
  transform: scale(1.1); /* Slightly enlarge on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Larger shadow on hover for a lifting effect */
}

.food-carousel .carousel-item p {
  margin-top: 5px; /* Spacing between image and text */
}


.music-section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px; /* Add space between elements */
}

.artist-name {
  display: flex;
  flex-direction: column;
  align-items: flex-end; /* Alinea el nombre del artista a la derecha */
  margin-right: 20px; /* Espacio entre el nombre del artista y la imagen */
}

.artist-name p {
  margin: 0;
  font-weight: bold;
  font-size: 1.2em; /* Ajusta el tamaño del texto del artista */
}

.instrument-list {
  list-style: none;
  padding: 0;
  margin-left: 20px; /* Espacio entre la imagen y la lista */
}

.instrument-list li {
  cursor: pointer;
  margin-bottom: 10px;
  transition: color 0.3s ease;
}

.instrument-list li:hover {
  color: var(--secondary-color); /* Change color on hover */
}

.instrument-list li.active {
  font-weight: bold;
  color: var(--accent-color);
}

.interest-list li {
  background-color: var(--secondary-color);
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.musician-image {
  width: 200px; /* Ajusta según necesidades */
  height: 300px; /* Altura fija para mantener proporciones */
  object-fit: cover; /* Evita deformaciones */
}

/* ===============================
                Modus Operandi
       =============================== */

.modus-operandi {
  font-family: 'Ubuntu', sans-serif;
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
}

.modus-operandi h1,
.modus-operandi h2 {
  text-align: center;
}

.modus-operandi p {
  text-align: justify;
  line-height: 1.5;
}

.modus-operandi .modus-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.modus-operandi .paragraphs-container {
  width: 60%; /* Ajusta este porcentaje según sea necesario */
}

.modus-operandi .modus-image {
  width: 40%; /* Ajusta este porcentaje según sea necesario */
  padding-left: 20px; /* Agrega espacio entre la imagen y los párrafos */
}

.modus-operandi .modus-image img {
  width: 100%; /* Hace que la imagen ocupe todo el ancho del contenedor */
  border-radius: 10%; /* Si quieres que la imagen sea circular */
}

/* ===============================
          Projects
     =============================== */
     
     projects-container h1,
.projects-container p {
  text-align: center;
  margin-bottom: 20px; 
}
     .projects-container {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      gap: 40px; /* Increased gap between cards */
      align-items: start;
      justify-content: center;
      margin-top: 10%;
      padding: 20px;
    }
    
    .project-item {
      background-color: var(--darker-main-color); /* Assuming you have this color variable defined */
      border-radius: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      padding: 15px;
      transition: transform 0.3s ease;
      animation: fadeIn 1s ease-in-out; /* Fade-in animation */
    }
    
    .project-item:hover {
      transform: translateY(-5px);
    }
    
    .folder-icon {
      width: 30px;
      margin-right: 10px;
    }
    
    .project-link {
      color: var(--text-color);
      text-decoration: none;
      font-weight: bold;
    }
    
    .project-link:hover {
      text-decoration: underline;
    }
    
    /* Fade-in animation */
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    
    /* ===============================
          Pestaña
     =============================== */
     .contact-tab {
      position: fixed;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background-color: rgba(0, 0, 0, 0.7);
      border: 1px solid var(#1b3427); /* Matching border color */
      padding: 10px;
      cursor: pointer;
      z-index: 1000;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 150px;
      border-radius: 0 10px 10px 0;
      transition: all 0.3s ease; /* Smooth transition for all properties */
    }
    
    .contact-tab.open {
      width: 150px; /* Adjusted width for expanded state */
      background-color: #1b3427;
    }
    
    .contact-tab img {
      display: block;
      width: 30px;
      margin-bottom: 10px;
      transition: transform 0.3s ease; /* Smooth transition for hover effect */
    }
    
    .contact-tab img:hover {
      transform: scale(1.1); /* Slightly enlarge icons on hover */
    }
    
    .contact-tab .arrow {
      font-size: 16px;
      color: var(--accent-color); /* Color that stands out */
      position: absolute;
      left: 80%; /* Adjust position for better visibility */
      top: 50%;
      transform: translateY(-50%);
    }
    
    .contact-tab:hover {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
      background-color: #1b3427
    }
    

/* ===============================
          Financial Skills
     =============================== */
    .centered-fin {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100vh;
      text-align: center;
    }
    

/* ===============================
          Responsiveness
     =============================== */
     @media screen and (max-width: 768px) {
      .burger-menu {
        display: flex;
        flex-direction: column;
      }
    
      .burger-button {
        margin-bottom: 20px; /* Space between burger icon and menu items */
      }
    
      .burger-menu a {
        padding: 10px;
        font-size: 1.2em; /* Larger font for easy touch */
      }

      .chart-info {
        position: auto;
        bottom: auto;
        transform: translate(auto-fill, auto-fill);
        color: #f8f0e5; /* El color del texto */
        font-size: 1.2em; /* El tamaño del texto */
        transform: translateY(-500%);
        transform: translatex(auto);
      }
      .softSkill-left, .softSkill-right, .softSkill-center {
        transition: transform 0.3s ease-in-out;
      }
      
      .softSkill-left:hover, .softSkill-right:hover {
        transform: scale(1.05); /* Slight zoom effect on hover */
      }
      .softSkill-left, .softSkill-right {
        transition: none;
        transform: none; /* Disable hover effects on mobile */
      }
      footer {
        padding: 30px 15px;
        text-align: center;
      }
      .contact-tab {
        position: fixed;
        left: 0; /* Alinea a la izquierda */
        transform: translateY(0); /* Anula la transformación para dispositivos móviles */
        width: 10%; /* Ancho completo en dispositivos móviles */
        height: 60px; /* Altura reducida para dispositivos móviles */
        border-radius: 25%; /* Borde redondeado eliminado en móviles */
        flex-direction: row; /* Cambia a disposición horizontal */
        justify-content: space-around; /* Distribuye el espacio alrededor de los elementos */
      }
    
      .contact-tab.open {
        width: 50%; /* Mantiene el ancho completo cuando está abierto */
      }
    
      .contact-tab img {
        width: 40px; /* Ajusta el tamaño de la imagen para móviles */
        margin-bottom: 0; /* Elimina el margen inferior para móviles */
      }
    
      .intro-dona-container {
        display: flex;
        width: 50%;
        height: 50%;
    }

    .banner-container {
      height: 10%; /* O una altura más pequeña si lo prefieres */
      width: 200%;
    }
    
    .intro-section {
      position: relative;
      width: auto;
      padding: 20px;
      box-sizing: border-box;
      background-color: transparent;
      color: white;
  }

    /* Parallax Layer */

.parallaxLayer {
  background-size: auto;
  width: auto;
}

.eggParachute {
  margin: 1vh auto 1vh 0; /* Margen: superior, derecho (auto centra), inferior, izquierdo (0 pone a la izquierda) */
  width: 20%; /* Controla el ancho de la imagen */
  height: auto; /* Mantiene la proporción de la imagen */
  position: sticky;
  top: 10%; /* Ajusta según necesites */
  display: block;
  margin: 1vh 0;
  width: 20%; /* Tamaño más pequeño */
  height: auto; /* Mantiene la proporción */
}

.softSkills-container { /* Asegúrate de aplicar la clase correcta del contenedor aquí */
  text-align: left; /* Alinea contenido a la izquierda */
}



.softSkill-left, .softSkill-right, .softSkill-center {
  width: auto;
  float: none;
  margin: 20px 10px;
}

.intro-dona-container {
  display: relative;
  width: 50%;
  height: 50%;
}
.banner-container {
height: auto; /* O una altura más pequeña si lo prefieres */
width: 200%;
}

.intro-section {
position: relative;
width: auto;
padding: 20px;
box-sizing: border-box;
background-color: transparent;
color: white;
}
    }
    

@media (min-width: 769px) and (max-width: 1024px) {
 
}

@media (min-width: 1025px) and (max-width: 1280px) {
  /* Your styles here */
}

@media (min-width: 1281px) {
  /* Your styles here */
}
